import API from './base-service';
const REACT_APP_STATIC_JSON_URL = process.env.REACT_APP_STATIC_JSON_URL;

const getStaticJsonUrl = () => {
    const id = JSON.parse(sessionStorage.getItem('id'));
    return REACT_APP_STATIC_JSON_URL.replace('{{id}}', id);
};

export const getStaticJsonState = async () => {
    const res = await fetch(getStaticJsonUrl());
    return await res.json();
};

export const login = async (email, password) => {
    const res = await API.post('/login', { email, password });
    sessionStorage.setItem('token', JSON.stringify(res.token));
    sessionStorage.setItem('id', JSON.stringify(res._id));
    console.log(res);
    return res;
};

export const uploadImage = async (file) => {
    let formData = new FormData();
    formData.append('file', file);
    try {
        return await API.postFormData('/upload', formData);
    } catch (e) {
        console.log(e);
    }
};

export const publish = (data) => API.post('/update-puppies', data);
