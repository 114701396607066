// const config = {
//     env: {
//         dev: {
//             api: 'http://localhost:4000',
//             staticJson: 'https://denniscalvert.s3.us-east-2.amazonaws.com/applicationState.json',
//         },
//         prod: {
//             api: 'https://rpp-admin.herokuapp.com',
//             staticJson: 'https://redpoppypets.s3.amazonaws.com/applicationState.json',
//         }
//     }
// };


import axios from 'axios';

const URI = process.env.REACT_APP_API_URL;

const config = () => ({
    "Authorization": `Bearer ${sessionStorage.getItem('token') || null}`,
    "x-access-token": JSON.parse(sessionStorage.getItem('token') || null),
});

const post = async (route, data) => {
    const res = await axios({
        method: 'POST',
        url: `${URI}${route}`,
        data,
        headers: config()
    });
    return res.data;
};

const postFormData = async (route, formData) => {
    const res = await axios({
        method: 'POST',
        url: `${URI}${route}`,
        data: formData,
        headers: {
            ...config(),
            'Content-Type': 'multipart/form-data'
        }
    });
    return res.data;
};

const get = async (route, params) => {
    const res = await axios({
        method: 'GET',
        url: `${URI}${route}`,
        headers: config(),
        params,
    });
    return res.data;
};

const destroy = async (route, data) => {
    const res = await axios({
        method: 'DELETE',
        url: `${URI}${route}`,
        data,
        headers: config()
    });
    return res.data;
};

const init = baseRoute => ({
    get: async (id) => await get(baseRoute, { id }),
    list: async (id) => await get(`${baseRoute}/list`, { id }),
    upsert: async (data) => await post(baseRoute, data),
    post: async (route, data) => await post(`${baseRoute}/${route}`, data),
    destroy: async (id) => await destroy(baseRoute, { id }),
});

export default {
    post,
    postFormData,
    get,
    destroy,
    init,
};