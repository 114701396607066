import React, { Component } from 'react';

// TODO
// Include breed options once app is wired up to database
// const breedOptions = [
//   'YorkShire Terrier',
//   'Malta-a-poo',
//   'Morkie',
//   'Shihpoo',
//   'Maltese Mix',
//   'Poodle',
//   'Maltese'
// ];

class ItemEditor extends Component {
  state = {
    isImgUploading: false
  }

  handleFileOnChange = async (e) => {
    this.setState({ isImgUploading: true });
    await this.props.handleFileUpload(e);
    this.setState({ isImgUploading: false });

  }

  generateInput = (item, field, type, optionsArray) => {
    const htmlId = `${field}_${item.id}`;

    return (
      <div className="form-group">
        <label>{field}</label>
        {
          type === 'text' &&
          <input value={item[field]} onChange={this.props.handleInputChange} type={type} className="form-control" id={htmlId} placeholder={field} />
        }
        {
          type === 'file' &&
          <input type={type} onChange={this.handleFileOnChange} className="form-control" id={htmlId} placeholder={field} />
        }
        {
          type === 'textarea' &&
          <textarea value={item[field]} onChange={this.props.handleInputChange} className="form-control" id={htmlId} placeholder={field} />
        }
        {
          type === 'checkbox' &&
          <input type="checkbox" id={htmlId} onChange={this.props.handleInputChange} className="form-control" />
        }
        {
          type === 'select' &&
          <select value={item[field]} onChange={this.props.handleInputChange} className="form-control" id={htmlId}>
            {optionsArray.map((o, i) => <option value={o} key={i}>{o}</option>)}
          </select>
        }
      </div>
    )
  }

  render() {
    const { item } = this.props;

    return (
      <div className="card">
        {
          this.state.isImgUploading
            ? <span className="imgLoading">LOADING<span className="loadingIndicator"></span></span>
            : <img src={item.imgUrl} className="card-img-top mb-3" alt={item.title} />
        }

        <div className="container">
          {this.generateInput(item, 'imgUrl', 'file')}
          {this.generateInput(item, 'title', 'text')}
          {this.generateInput(item, 'price', 'text')}
          {this.generateInput(item, 'description', 'textarea')}
          {/* {this.generateInput(item, 'breed', 'select', breedOptions)} */}
          {this.generateInput(item, 'gender', 'select', ['Male', 'Female'])}
          {/* {this.generateInput(item, 'isPublic', 'checkbox')} */}
          <div className="form-group">
            <button className="btn btn-danger" type="button" onClick={() => this.props.handleDeleteItem(item.id)}>Delete</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ItemEditor;
