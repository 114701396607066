import React, { Component } from 'react';
import './App.css';
import ItemList from './components/item-list';
// import { v4 as uuid } from 'uuid';
import { login } from './lib/services';


class App extends Component {

  constructor() {
    super()
    const hasToken = JSON.parse(sessionStorage.getItem('token')) ? true : false;
    this.state = {
      isLoggedIn: hasToken,
      isLoading: false,
      userName: '',
      password: '',
    };
  }

  handleNameChange = (e) => {
    this.setState({ userName: e.target.value });
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  }

  handleLogin = async () => {
    this.setState({ isLoading: true });
    try {
      await login(this.state.userName, this.state.password);
      this.setState({
        isLoggedIn: true,
        isLoading: false,
      })
    } catch (e) {
      this.setState({ error: JSON.stringify(e) });
    }
  }

  isSubmitDisabled = () => {
    return this.state.userName.length < 2 && this.state.password.length < 2 && !this.state.isLoading;
  }

  renderLogin = () => {
    return (
      <div className="container-fluid">
        <header className="App-header">
          <div className="row">
            <div className="col-sm-9">
              <span className="app-title align-middle">Red Poppy Pets Admin</span>
            </div>
          </div>
        </header>

        <div className="card loginform">
            <h1>Login</h1>
              Email
              <input type="text" onChange={this.handleNameChange} value={this.state.userName} className="form-control mb4" />
              Password
              <input type="password" onChange={this.handlePasswordChange} value={this.state.password} className="form-control mb4" />
            <button disabled={this.isSubmitDisabled()} onClick={this.handleLogin} className="btn btn-default btn-success form-control">Submit</button>
            {
              this.state.error &&
              <div class="danger">{this.state.error}</div>
            }
        </div >
      </div>
    );
  }

  render() {
    return (
      this.state.isLoggedIn
        ? <ItemList />
        : this.renderLogin()
    );
  }
}

export default App;
