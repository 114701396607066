import React, { Component } from 'react';
import ItemEditor from './itemEditor';
import { v4 as uuid } from 'uuid';

import {
    getStaticJsonState,
    uploadImage,
    publish,
} from '../lib/services';


class ItemList extends Component {

    constructor() {
        super()

        const localData = JSON.parse(window.localStorage.getItem('appState')) || {};

        this.state = {
            items: localData.items || [],
            token: localData.token || '',
            newToken: ''
        };
    }

    async componentDidMount() {
        const res = await getStaticJsonState()
        if(res.items) {
            this.setState({ items: res.items });
        }
    }

    componentDidUpdate() {
        window.localStorage.setItem('appState', JSON.stringify(this.state));
    }

    handleFileUpload = async (e) => {
        this.setState({ isLoading: true });
        const id = e.target.id.split("_")[1];
        const res = await uploadImage(e.target.files[0]);
        this.setState({
            isLoading: false,
            items: this.state.items.map(i => {
                if (i.id === id) {
                    i.imgUrl = res.imgUrl;
                }
                return i;
            })
        });
    }

    handleItemChange = (e) => {
        const item = e.target;
        const id = item.id.split("_")[1];
        const field = item.id.split("_")[0];

        const newItemArray = this.state.items.map(i => {
            if (i.id === id) {
                i[field] = item.value
            }
            return i;
        });

        this.setState({ items: newItemArray });
    }

    handleAddItem = () => {
        const newItem = {
            id: uuid(),
            title: "",
            imgUrl: "",
            price: "",
            description: "",
            isPublic: true,
        };

        this.setState({ items: this.state.items.concat(newItem) })
    }

    handleDeleteItem = (id) => {
        this.setState({ items: this.state.items.filter(i => i.id !== id) })
    }

    handleNewTokenUpdate = (e) => {
        this.setState({ newToken: e.target.value });
    }

    setToken = () => {
        this.setState({
            token: this.state.newToken,
            newToken: null
        });
    }

    isTokenValid = () => {
        const invalidValues = ['', 'null', null];
        return invalidValues.every(val => this.state.token !== val);
    }

    publish = async () => {
        this.setState({ isLoading: true });
        const data = this.state.items.map(item => ({
                ...item,
                gender: item.gender || 'Male',
        }));
        await publish(data)
        this.setState({ isLoading: false });

        // TODO
        // get this data persisting to MongoDB
        // dataSvc.publish(this.state)
    }

    render() {
        return (
            <div className="container-fluid">
                <header className="App-header mb-4">
                    <div className="row">
                        <div className="col-sm-8">
                            <span className="app-title align-middle">Red Poppy Pets Admin</span>
                        </div>
                        <div className="col-sm-2">
                            <a className="btn btn-default btn-success form-control" rel="noopener noreferrer" href="http://redpoppypets.s3-website-us-east-1.amazonaws.com/puppies.html" target="_blank">Preview</a>
                        </div>
                        <div className="col-sm-2">
                            <button onClick={this.publish} className="btn btn-default btn-success form-control">Publish</button>
                        </div>
                    </div>
                </header>

                <div className="row">
                    {
                        this.state.items.map((pup, i) => {
                            return (
                                <div key={i} className="col-sm-3 mb-4">
                                    <ItemEditor
                                        item={pup}
                                        handleInputChange={this.handleItemChange}
                                        handleDeleteItem={this.handleDeleteItem}
                                        handleFileUpload={this.handleFileUpload}
                                        className="col-sm-4" />
                                </div>
                            )
                        })
                    }
                    < div className="col-sm-3 mb-1" >
                        <div className="card p-3">
                            <div className="card-block">
                                <button className="btn btn-primary form-control" onClick={this.handleAddItem}>+ Add another to the list</button>
                            </div>
                        </div>
                    </div >
                </div>
                {
                    this.state.isLoading &&
                    <div class="loading">
                        <h1>LOADING</h1>
                    </div>
                }
            </div >
        );
    }
}

export default ItemList;
